

//@ts-ignore
import * as cbconn from '@corebos/ws-lib/WSClientm';


export interface CbUser {
    userId: string;
    sessionName: string;
    profile?: any;
}

export interface SessionData {
    sessionName: string;
    userId: string;
}


export const cbWebService = (apiUrl: string) => {
    cbconn.setURL(apiUrl);
    return cbconn;
}

export const cbWSSetSession = (sessionData: SessionData): void => {
    cbconn.setSession(sessionData);
}

export const cbLogin = async (apiUrl: string, username: string, password: string, withpassword: boolean = false): Promise<CbUser | null>  => {
    cbconn.setURL(apiUrl);
    const logindata = await cbconn.doLogin(username, password, withpassword).catch(() => null);
    if(logindata){
        return null;
    }
    if(logindata !== false && cbconn.hasError(logindata) === false){
        const coreboslogindata = logindata['result'];
        const session: SessionData = { sessionName: coreboslogindata?.sessionName, userId: coreboslogindata?.userId };
        cbWSSetSession(session);
        return {...session, ...{profile: {}}}
    } else {
        return null;
    }
}