
import * as React from "react";
import {
  PlasmicTripPlanStep2,
  DefaultTripPlanStep2Props
} from "./plasmic/ai_tourist_code_components/PlasmicTripPlanStep2";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { getFeaturesGroup } from "./FeaturesList";


export interface TripPlanStep2Props extends DefaultTripPlanStep2Props {}

function TripPlanStep2_(
  props: TripPlanStep2Props,
  ref: HTMLElementRefOf<"div">
) {


  return (
    <PlasmicTripPlanStep2 
      root={{ref}} 
      {...props}
      firstFeaturesGroup={getFeaturesGroup('what')}
      secondFeaturesGroup={getFeaturesGroup('what')}
      thirdFeaturesGroup={getFeaturesGroup('what')}
    />
  )
}

const TripPlanStep2 = React.forwardRef(TripPlanStep2_);
export default TripPlanStep2;
