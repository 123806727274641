import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';


export interface TripChoice {
    selectedCities: any[];
    isFlexible: boolean;
    startDate?: string;
    endDate?: string;
    selectedTripMonths?: string[];
    duration?: string;
    pace: string;
    thingsToDo: string[];
    tripReasons: string[];
}

const tripChoicesInitialState: TripChoice = {
    selectedCities: [],
    isFlexible: false,
    startDate: '',
    endDate: '',
    selectedTripMonths: [],
    duration: '',
    pace: 'Standard',
    thingsToDo: [],
    tripReasons: [],
}

export const tripChoicesSlice = createSlice({
    name: 'tripChoices',
    initialState: {
        tripChoices: tripChoicesInitialState
    },
    reducers: {
        addCity: (state: any, action: any) => {
            const { id } = action.payload;
            const existingCity = state?.tripChoices?.selectedCities?.find((city: any) => city.id === id);
            if(!existingCity) {
                state.tripChoices.selectedCities = [...state.tripChoices.selectedCities, action.payload];
            }
        },
        removeCity: (state: any, action: any) => {
            const { id } = action.payload;
            state.tripChoices.selectedCities = state.tripChoices.selectedCities?.filter((item: any) => item.id !== id);
        },
        clearCities: (state: any) => {
            state.tripChoices.selectedCities = [];
        },
        setTripDate: (state: any, action: any) => {
            const { startDate, endDate } = action.payload;
            state.tripChoices.startDate = startDate;
            state.tripChoices.endDate = endDate;
            state.tripChoices.isFlexible = false;
            state.tripChoices.selectedTripMonths = [];
            const duration = dayjs(new Date(endDate)).diff(dayjs(new Date(startDate)), 'week');
            state.tripChoices.duration = duration >= 4 ? `${duration} month` : `${duration} weeks`;
        },
        setTripDuration: (state: any, action: any) => {
            const { months, duration } = action.payload;
            state.tripChoices.isFlexible = true;
            state.tripChoices.selectedTripMonths = months;
            state.tripChoices.duration = duration;
            state.tripChoices.startDate = '';
            state.tripChoices.endDate = '';
        },
        handleTripMonth: (state: any, action: any) => {
            const { month, duration } = action.payload;
            const existing = state?.tripChoices?.selectedTripMonths?.find((selectedMonth: string) => selectedMonth === month);
            if(!existing) {
                state.tripChoices.selectedTripMonths = [...state.tripChoices.selectedTripMonths, month];
            } else {
                state.tripChoices.selectedTripMonths = state.tripChoices.selectedTripMonths?.filter((selectedMonth: string) => selectedMonth !== month);
            }
            state.tripChoices.isFlexible = true;
            state.tripChoices.duration = duration;
            state.tripChoices.startDate = '';
            state.tripChoices.endDate = '';
        },
        setTripPace: (state: any, action: any) => {
            const { pace } = action.payload;
            state.tripChoices.pace = pace;
        },
        handleTripCases: (state: any, action: any) => {
            const { caseType, value } = action.payload; // type can be `what` or `why`
            let currentData: string[] = [];
            let dataKey: string = '';
            if(caseType === 'what'){
                currentData = state?.tripChoices?.thingsToDo;
                dataKey = 'thingsToDo';
            }
            if(caseType === 'why'){
                currentData = state?.tripChoices?.tripReasons;
                dataKey = 'tripReasons';
            }
            const existing = currentData?.find((item: string) => item === value);
            if(!existing) {
                currentData = [...currentData, value];
            } else {
                currentData = currentData?.filter((item: string) => item !== value);
            }
            state.tripChoices[dataKey] = currentData;
        },
    },
});

export const { addCity, removeCity, clearCities, setTripDate, setTripDuration, setTripPace, handleTripMonth, handleTripCases } = tripChoicesSlice.actions;
export const tripChoices = (state: any) => state?.tripChoices.tripChoices;
export const selectedCitiesList = (state: any) => state?.tripChoices.tripChoices.selectedCities;
export const tripPace = (state: any) => state?.tripChoices.tripChoices.pace;
export const tripDuration = (state: any) => state?.tripChoices.tripChoices.duration;
export const tripMonths = (state: any) => state?.tripChoices.tripChoices.selectedTripMonths;


export const tripChoiceReducer = tripChoicesSlice.reducer;