import React from 'react'
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { setTripPace, tripPace } from '../store/reducers';


export interface PaceItem {
    label: string;
    value: string;
}

const paceList: PaceItem[] = [
    {label: 'Sedentary', value: 'Sedentary'},
    {label: 'Slow', value: 'Slow'},
    {label: 'Standard', value: 'Standard'},
    {label: 'Dynamic', value: 'Dynamic'}
]


const PaceSelectorButtonGroup = ({className, title} : {className: string, title: any}) => {

    const dispatch = useDispatch();
    const currentPace = useSelector(tripPace);

    

    return (
        <>
            <div className={className} >{title}</div>
            <div className={className} style={{ width: 'inherit' }}>
                {
                    paceList.map((pace: PaceItem) => {
                        
                        return (
                            <Button 
                                key={pace.value} 
                                color={ currentPace === pace.value ? 'blue' : 'clear' }
                                style={{border: '1px solid #ddd'}}
                                onClick={() => {
                                    const value: any = {pace: pace.value};
                                    dispatch(setTripPace(value));
                                }}
                                title={pace.label}
                            >
                                {pace.label}
                            </Button>
                        )
                    })
                }
            </div>
        </>
    )

}

export default PaceSelectorButtonGroup