import React from 'react'
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { handleTripCases, tripChoices } from '../store/reducers';
import { CheckCircleFilled } from '@ant-design/icons';



export const getFeaturesGroup = (caseType: 'what' | 'why' | 'proposal') => ({ wrapChildren: (children: any) =>  <FeaturesList caseType={caseType} content={children} /> })

const FeaturesList = ({ content, caseType }: { content?: any, caseType:  'what' | 'why' | 'proposal'}) => {

    const { props } = content;
    const dispatch = useDispatch();
    const {thingsToDo, tripReasons} = useSelector(tripChoices);

    
    const handleSelection = (value: string) => {
        if(caseType === 'proposal'){
            // check for proposals
        } else {
            const data: any = {caseType: caseType, value: value};
            dispatch(handleTripCases(data));
        }
    }

    return (
        <> { props?.children.map((child: any, index: number) => {

            const caseTypes: string[] = caseType === 'what' ? thingsToDo : tripReasons;
            
            return (
                <Button color="clear" key={`${child.props?.value}${index}`} onClick={() => handleSelection(child.props?.value)} style={{padding: 0, background: 'none', border: 'none', boxShadow: 'none'}}>
                    {child}
                    {caseTypes.includes(child.props?.value) && <CheckCircleFilled style={{position: 'absolute', top: '15px', right: '10px'}} />}
                </Button>
            )
        })} </>
    )

}

export default FeaturesList