import * as React from "react";
import {
  PlasmicTripPlanStep4,
  DefaultTripPlanStep4Props
} from "./plasmic/ai_tourist_code_components/PlasmicTripPlanStep4";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { getFeaturesGroup } from "./FeaturesList";

export interface TripPlanStep4Props extends DefaultTripPlanStep4Props {}

function TripPlanStep4_(
  props: TripPlanStep4Props,
  ref: HTMLElementRefOf<"div">
) {

  return <PlasmicTripPlanStep4 root={{ ref }} {...props} proposalCards={getFeaturesGroup('proposal')} />;
}

const TripPlanStep4 = React.forwardRef(TripPlanStep4_);
export default TripPlanStep4;
