import React from 'react'
import SelectedCityItem from './SelectedCityItem'
import { useDispatch, useSelector } from 'react-redux';
import { removeCity, selectedCitiesList } from '../store/reducers';





const SelectedCities = ({className, cities = [], unselectCity, props = {}} : {className?: string, cities: any[], unselectCity?: any, props?: any}) => {

    const dispatch = useDispatch();
    const selectedCities = useSelector(selectedCitiesList);

    return (
        <div className={className}>
            {
                selectedCities?.map((city: any, index: number) => {

                    return (
                        <SelectedCityItem 
                            key={city.id}
                            city={city}
                            {...props}
                            choiceNumberValue={index}
                            cityTitle={{
                                props: {
                                    children: city.potentialname
                                }
                            }}
                            cancelIcon={{
                                props: {
                                onClick: () => dispatch(removeCity(city)),
                                }
                            }} 
                        />
                    )
                })
            }
        </div>
    )

}

export default SelectedCities