import React from 'react'
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { handleTripMonth, tripDuration, tripMonths,  } from '../store/reducers';


export interface TripMonthItem {
    label: string;
    value: string;
}

const tripMonthsList: TripMonthItem[] = [
    {label: 'January', value: 'January'},
    {label: 'February', value: 'February'},
    {label: 'March', value: 'March'},
    {label: 'April', value: 'April'},
    {label: 'May', value: 'May'},
    {label: 'June', value: 'June'},
    {label: 'Jully', value: 'Jully'},
    {label: 'August', value: 'August'},
    {label: 'September', value: 'September'},
    {label: 'October', value: 'October'},
    {label: 'November', value: 'November'},
    {label: 'December', value: 'December'},
]


const TripMonthsSelectorButtonGroup = ({className} : {className: string}) => {

    const dispatch = useDispatch();
    const currentDuration = useSelector(tripDuration);
    const selectedTripMonths = useSelector(tripMonths);


    return (
        <div className={className} style={{ width: 'inherit' }}>
            {
                tripMonthsList.map((item: TripMonthItem) => {
                    
                    return (
                        <Button 
                            key={item.value} 
                            color={ selectedTripMonths.includes(item.value) ? 'blue' : 'clear' }
                            style={{border: '1px solid #ddd'}}
                            onClick={() => {
                                const value: any = {month: item.value, duration: currentDuration};
                                dispatch(handleTripMonth(value));
                            }}
                            title={item.label}
                        >
                            {item.label}
                        </Button>
                    )
                })
            }
        </div>
    )

}

export default TripMonthsSelectorButtonGroup