import React from 'react'
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { setTripDuration, tripDuration, tripMonths } from '../store/reducers';


export interface DurationItem {
    label: string;
    value: string;
}

const durationList: DurationItem[] = [
    {label: '1 Week', value: '1 Week'},
    {label: '2 Weeks', value: '2 Weeks'},
    {label: '3 Weeks', value: '3 Weeks'},
    {label: '1 Months', value: '1 Months'}
]


const DurationSelectorButtonGroup = ({className, title} : {className: string, title: any}) => {

    const dispatch = useDispatch();
    const currentDuration = useSelector(tripDuration);
    const selectedTripMonths = useSelector(tripMonths);

    

    return (
        <>
            <div className={className} >{title}</div>
            <div className={className} style={{ width: 'inherit' }}>
                {
                    durationList.map((item: DurationItem) => {
                        
                        return (
                            <Button 
                                key={item.value} 
                                color={ currentDuration === item.value ? 'blue' : 'clear' }
                                style={{border: '1px solid #ddd'}}
                                onClick={() => {
                                    const value: any = {months: selectedTripMonths, duration: item.value};
                                    dispatch(setTripDuration(value));
                                }}
                                title={item.label}
                            >
                                {item.label}
                            </Button>
                        )
                    })
                }
            </div>
        </>
    )

}

export default DurationSelectorButtonGroup