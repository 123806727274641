import { PlasmicImg } from '@plasmicapp/react-web';
import React from 'react'

const WelcomeMedia = ({title, className, mediaUrl} : {title: string, mediaUrl: string, className: string}) => {

  return (
    <PlasmicImg
      alt={title}
      className={className}
      src={mediaUrl}
    />
  )
}

export default WelcomeMedia;
