// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { cloneElement, useState } from "react";
import {
  PlasmicWelcomeIntro,
  DefaultWelcomeIntroProps
} from "./plasmic/ai_tourist_code_components/PlasmicWelcomeIntro";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { message } from "antd";
import { AimOutlined,  CalendarOutlined, UnorderedListOutlined, WarningOutlined, CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { TripStepItem } from "./TripPlanStepper";
import TripPlanStep0 from "./TripPlanStep0";
import TripPlanStep1 from "./TripPlanStep1";
import TripPlanStep2 from "./TripPlanStep2";
import TripPlanStep3 from "./TripPlanStep3";
import TripPlanStep4 from "./TripPlanStep4";



export interface WelcomeIntroProps extends DefaultWelcomeIntroProps {}

const ModalCloseIcon = ({handleModal, open} : {handleModal: any, open: boolean}) => <CloseOutlined onClick={() => handleModal(open)} />

const tripSteps: TripStepItem[] = [
  {title: 'Dove', icon: <AimOutlined />, content: <TripPlanStep0 />, disabled: false },
  {title: 'Quando', icon: <CalendarOutlined />, content: <TripPlanStep1 />, disabled: false },
  {title: 'Perché', icon: <UnorderedListOutlined />, content: <TripPlanStep2 />, disabled: false },
  {title: 'Cosa', icon: <WarningOutlined />, content: <TripPlanStep3 />, disabled: false },
  {title: 'Risultati', icon: <CheckCircleOutlined />, content: <TripPlanStep4 />, disabled: false },
];

const WelcomeIntro_ = (props: WelcomeIntroProps, ref: HTMLElementRefOf<"div">) => {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);


  const goNextStep = (currentStep: number) => {
    if(currentStep + 1 === tripSteps.length){
      message.success('Processing complete!');
    }
    setStep(currentStep);
  };

  const goPreviousStep = (currentStep: number) => {
    setStep(currentStep - 1);
  };


  const handleModal = (open: boolean = false) => {
    setIsModalOpen(open);
  }



  return (
    <PlasmicWelcomeIntro 
      root={{ ref }} 
      {...props}
      actionButton={{
        props: {
          onClick: () => handleModal(true),
        }
      }} 
      tripPlanModal={{
        props: {
          isModalOpen: isModalOpen,
          closeIcon: <ModalCloseIcon handleModal={handleModal} open={!isModalOpen} />,
        },
      }}
      tripPlanStepper={{
        props: {
          steps: tripSteps,
          currentStep: step
        }
      }}
      stepPreviousButton={{
        props: {
          onClick: () => goPreviousStep(step),
          isDisabled: step === 0
        }
      }}
      stepNextButton={{
        props: {
          onClick: () => goNextStep(step + 1),
          isDisabled: step === (tripSteps.length - 1)
        }
      }}
      tripModalMediaGrid={{
        wrap: (node: any) => step === (tripSteps.length - 1) ? null : node
      }}
      tripModalContentGrid={{
        wrap: (node: any) => step === (tripSteps.length - 1) ? cloneElement(node, { ...{style: {width: 'auto', margin: 'auto'}} }) : node
      }}
    />
)
}

const WelcomeIntro = React.forwardRef(WelcomeIntro_);
export default WelcomeIntro;
