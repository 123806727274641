import { Modal } from "antd";
import React, { ReactNode } from "react";

const TripPlanModal = ({className, modalContent, title, isModalOpen, closeIcon} : {className?: string, modalContent?: any, title?: string, isModalOpen: boolean, closeIcon?:  ReactNode}) => {



    return (
        <Modal className="tripPlanModal" title={title} open={isModalOpen} footer={null} closeIcon={closeIcon} width="100%">
            <div className={className}> {modalContent} </div>
        </Modal>
    );
};

export default TripPlanModal;
