import React, { ReactNode } from 'react';
import { Steps, StepProps } from 'antd';



export interface TripStepItem extends StepProps {
    content?: ReactNode;
};

const TripPlanStepper = ({className, steps = [], currentStep = 0, stepperActions} : {className: string, steps: TripStepItem[], currentStep: number, stepperActions?: ReactNode}) => {


    const items = steps.map((item: TripStepItem) => ({ key: item.title, title: item.title, icon: item.icon, subTitle: item?.subTitle, disabled: item.disabled, onClick: item.onClick, description: item.description, status: item.status, className: item.className }));

    return (
        <div className={className}>
            <Steps current={currentStep} items={items} labelPlacement="vertical" />
            <div className={className} style={{marginTop: 50}} >{steps[currentStep]?.content}</div>
            <div className={className}> {stepperActions} </div>
        </div>
    );
};

export default TripPlanStepper;
