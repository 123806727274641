import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PlasmicHost from './pages/PlasmicHost';
import Homepage from './components/Homepage';
import { CORE_BOS_URL } from './config';


//@ts-ignore
import * as cbconn from '@corebos/ws-lib/WSClientm';



cbconn.setURL(CORE_BOS_URL);

const App = ()  => {

  return (
    <div className="App">
      <Routes>
        <Route  path='/plasmic-host' element={<PlasmicHost />} />
        <Route  path='/' element={<Homepage />} />
      </Routes>
    </div>
  );
}

export default App;