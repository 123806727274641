import * as React from "react";
import {
  PlasmicTripPlanStep3,
  DefaultTripPlanStep3Props
} from "./plasmic/ai_tourist_code_components/PlasmicTripPlanStep3";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { getFeaturesGroup } from "./FeaturesList";

export interface TripPlanStep3Props extends DefaultTripPlanStep3Props {}

function TripPlanStep3_(
  props: TripPlanStep3Props,
  ref: HTMLElementRefOf<"div">
) {


  return <PlasmicTripPlanStep3 root={{ ref }} {...props} firstFeaturesGroup={getFeaturesGroup('why')} />;
}

const TripPlanStep3 = React.forwardRef(TripPlanStep3_);
export default TripPlanStep3;
