import { PlasmicCanvasHost, registerComponent, registerFunction } from '@plasmicapp/react-web/lib/host'
import React from 'react'
import WelcomeMedia from '../components/WelcomeMedia';
import TripPlanModal from '../components/TripPlanModal';
import TripPlanStepper from '../components/TripPlanStepper';
import { cbLogin, cbWebService } from '../components/utils';
import SelectedCities from '../components/SelectedCities';
import MainLayout from '../components/MainLayout';
import CitySearch from '../components/CitySearch';
import DateRangeSelector from '../components/DateRangeSelector';
import PaceSelectorButtonGroup from '../components/PaceSelectorButtonGroup';
import DurationSelectorButtonGroup from '../components/DurationSelectorButtonGroup';
import TripMonthsSelectorButtonGroup from '../components/TripMonthsSelectorButtonGroup';




registerComponent(WelcomeMedia, {
  name: 'WelcomeMedia',
  importPath: "./src/components/WelcomeMedia",
  isDefaultExport: true,
  props: {
    title: {
      type: "string",
      defaultValue: "Introduction Welcome Photo"
    },
    mediaUrl: {
      type: "string",
    }
  }
});

registerComponent(TripPlanModal, {
  name: 'TripPlanModal',
  importPath: "./src/components/TripPlanModal",
  isDefaultExport: true,
  props: {
    title: {
      type: "string",
    },
    modalContent: {
      type: "slot",
    },
    closeIcon: {
      type: "slot",
    },
    isModalOpen: {
      type: "boolean",
      defaultValue: false
    }
  }
});

registerComponent(TripPlanStepper, {
  name: 'TripPlanStepper',
  importPath: "./src/components/TripPlanStepper",
  isDefaultExport: true,
  props: {
    steps: {
      type: "array",
    },
    currentStep: {
      type: "number",
      defaultValue: 0,
    },
    stepperActions: {
      type: "slot",
    },
  }
});

registerComponent(SelectedCities, {
  name: 'SelectedCities',
  importPath: "./src/components/SelectedCities",
  isDefaultExport: true,
  props: {
    cities: {
      type: "array",
    },
    unselectCity: {
      type: "eventHandler",
      argTypes: [{name: "city", type: "object"}]
    },
  }
});

registerComponent(MainLayout, {
  name: 'MainLayout',
  importPath: "./src/components/MainLayout",
  isDefaultExport: true,
  props: {
    children: {
      type: "slot",
    },
  }
});

registerComponent(CitySearch, {
  name: 'CitySearch',
  importPath: "./src/components/CitySearch",
  isDefaultExport: true,
  props: {
    inputLabel: {
      type: "string",
      defaultValue: 'Search'
    },
    handleCitySelection: {
      type: "eventHandler",
      argTypes: [{name: "selectedCity", type: "object"}]
    },
  }
});

registerComponent(DateRangeSelector, {
  name: 'DateRangeSelector',
  importPath: "./src/components/DateRangeSelector",
  isDefaultExport: true,
  props: {
    labels: {
      type: "array",
      defaultValue: ["Departure date", "Return date"]
    },
  }
});

registerComponent(PaceSelectorButtonGroup, {
  name: 'PaceSelectorButtonGroup',
  importPath: "./src/components/PaceSelectorButtonGroup",
  isDefaultExport: true,
  props: {
    title: {
      type: "string",
      defaultValue: "Which pace do you prefer?"
    },
  }
});

registerComponent(DurationSelectorButtonGroup, {
  name: 'DurationSelectorButtonGroup',
  importPath: "./src/components/DurationSelectorButtonGroup",
  isDefaultExport: true,
  props: {
    title: {
      type: "string",
      defaultValue: "Duration"
    },
  }
});

registerComponent(TripMonthsSelectorButtonGroup, {
  name: 'TripMonthsSelectorButtonGroup',
  importPath: "./src/components/TripMonthsSelectorButtonGroup",
  isDefaultExport: true,
  props: {}
});


registerFunction(cbWebService, {
  name: 'cbWebService',
  importPath: "./src/components/utils",
  isDefaultExport: false,
  params: [
    {
      name: "apiUrl",
      type: "string",
      description: "This is the url for the API"
    },
  ],
  returnValue: {
    type: "any",
    description: "The cbWebService object which have methods to call the API"
  },
});

registerFunction(cbLogin, {
  name: 'cbLogin',
  namespace: 'authentication',
  importPath: "./src/components/utils",
  isDefaultExport: false,
  params: [
    {
      name: "apiUrl",
      type: "string",
      description: "This is the url for the API"
    },
    {
      name: "username",
      type: "string",
      description: "The username of the authenticating user"
    },
    {
      name: "password",
      type: "string",
      description: "The password/accessKey of the authenticating user"
    },
    {
      name: "withpassword",
      type: "boolean",
      description: "This value used either to use hashed password or not"
    }
  ],
  // @cbUser data
  returnValue: {
    type: "any",
    description: "This return either user with session data if it finds, otherwise returns null"
  },
});


const PlasmicHost = () => {
  return (
    <PlasmicCanvasHost />
  )
}

export default PlasmicHost;
