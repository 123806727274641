import { AutoComplete } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addCity } from '../store/reducers';


//@ts-ignore
import * as cbconn from '@corebos/ws-lib/WSClientm';



const CitySearch = ({className, inputLabel = 'Search...', handleCitySelection = null} : {className?: string, inputLabel?: string, handleCitySelection: any})  => {

    const dispatch = useDispatch();
    const [value, setValue] = useState<string>('');
    const [options, setOptions] = useState<any[]>([]);


    const search = async (searchText: string) => {
        if(!searchText) {
            return []
        }
        
        const query: string = `SELECT * FROM Potentials WHERE potentialname LIKE '%${searchText}%' ORDER BY potentialname DESC LIMIT 0,10`;
        const result = await cbconn.doQuery(query).catch(() => []);
        const found: any[] = result.map((Potential: any) => ({key: Potential.id, label: Potential.potentialname, value: Potential.potentialname, city: Potential}));
        
        return found;
    }

    const onChange = (data: string) => {
        setValue(data);
    };

    const onSelect = (data: any) => {
        dispatch(addCity(data))
        setValue('');
    };


    return (
        <div className={className}>
            <AutoComplete
                onSelect={(_value, option) => {
                    onSelect(option?.city);
                }}
                onSearch={async (text: string) => setOptions(await search(text))}
                onChange={onChange}
                value={value}
                options={options}
                style={{ width: 'inherit', height: 'inherit' }}
                allowClear
                placeholder={inputLabel}
            />
        </div>
    )
}

export default CitySearch