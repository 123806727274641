import React, { useEffect, useState } from 'react'
import { SessionData } from './utils';
import { CORE_BOS_KEY, CORE_BOS_USER } from '../config';


//@ts-ignore
import * as cbconn from '@corebos/ws-lib/WSClientm';

const MainLayout = ({className, children} : {className?: string, children: any}) => {

    const [session, setSession] = useState<SessionData | null>(null);

    useEffect(() => {
        const login = async () => {
          const logindata = await cbconn.doLogin(CORE_BOS_USER, CORE_BOS_KEY, false).catch(() => null);
          if(logindata !== false && cbconn.hasError(logindata) === false){
              const coreboslogindata = logindata['result'];
              const sessionData: SessionData = { sessionName: coreboslogindata?.sessionName, userId: coreboslogindata?.userId };
              cbconn.setSession(sessionData);
              setSession(sessionData);
          }
        }
        login();
    }, [])

    if(!session){
        return null
    }

  return (
    <div className={className}> {children} </div>
  )

}


export default MainLayout